<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.driverName")
                    }}</label>
                    <a-select
                        show-search
                        v-model="model.driver_id"
                        style="width: 100%"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        :placeholder="$t('requestDelivery.typeToSearch')"
                        @search="handleDriverSearch"
                    >
                        <a-select-option
                            v-for="c in driverList"
                            :key="c.driver_id"
                        >
                            {{ c.driver_name }}
                        </a-select-option>
                    </a-select>
                    <div class="tw-text-red-500" v-if="errors.has('driver_id')">
                        {{ errors.first("driver_id") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.vehicleType")
                    }}</label>
                    <a-select
                        v-model="model.vehicle_type_id"
                        class="tw-w-full"
                        :options="vehicleType"
                    ></a-select>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('vehicle_type_id')"
                    >
                        {{ errors.first("vehicle_type_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.serviceType")
                    }}</label>
                    <a-select
                        v-model="model.service_type_id"
                        class="tw-w-full"
                        :options="serviceType"
                    ></a-select>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('service_type_id')"
                    >
                        {{ errors.first("service_type_id") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.status")
                    }}</label>
                    <a-select
                        v-model="model.delivery_status_id"
                        class="tw-w-full"
                        :options="deliveryStatus"
                        @change="onStatusChange"
                    ></a-select>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('delivery_status_id')"
                    >
                        {{ errors.first("delivery_status_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("requestDelivery.receiverName") }}</label>
                    <input
                        v-model.trim="model.receiver_name"
                        type="text"
                        :placeholder="$t('requestDelivery.receiverName')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('receiver_name')"
                    >
                        {{ errors.first("receiver_name") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.receiverPhone")
                    }}</label>
                    <input
                        v-model.trim="model.receiver_phone"
                        type="text"
                        :placeholder="$t('requestDelivery.receiverPhone')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('receiver_phone')"
                    >
                        {{ errors.first("receiver_phone") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.location")
                    }}</label>
                    <input
                        v-model.trim="model.receiver_location"
                        type="text"
                        :placeholder="$t('requestDelivery.location')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('receiver_location')"
                    >
                        {{ errors.first("receiver_location") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.fxRate")
                    }}</label>
                    <input
                        v-model="model.fx_rate"
                        type="number"
                        :placeholder="$t('requestDelivery.fxRate')"
                        class="form-control"
                    />
                    <div class="tw-text-red-500" v-if="errors.has('fx_rate')">
                        {{ errors.first("fx_rate") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.pricePackage")
                    }}</label>
                    <input
                        v-model="model.cod_amount"
                        type="number"
                        :placeholder="$t('requestDelivery.pricePackage')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('cod_amount')"
                    >
                        {{ errors.first("cod_amount") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.agencyFee")
                    }}</label>
                    <input
                        v-model="model.agency_fee"
                        type="number"
                        :placeholder="$t('requestDelivery.agencyFee')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('agency_fee')"
                    >
                        {{ errors.first("agency_fee") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("requestDelivery.packagingFee") }}</label>
                    <input
                        v-model="model.packaging_fee"
                        type="number"
                        :placeholder="$t('requestDelivery.packagingFee')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('packaging_fee')"
                    >
                        {{ errors.first("packaging_fee") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.pickUpFee")
                    }}</label>
                    <input
                        v-model="model.pickup_fee"
                        type="number"
                        :placeholder="$t('requestDelivery.pickUpFee')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('pickup_fee')"
                    >
                        {{ errors.first("pickup_fee") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label tw-text-xs">{{
                                $t("requestDelivery.km")
                            }}</label>
                            <div>
                                <input
                                    style="width: 97%"
                                    v-model.number="model.distance_km"
                                    class="form-control"
                                    :placeholder="$t('requestDelivery.km')"
                                />
                            </div>
                        </div>
                        <div class="col-md-6 tw-space-y-1">
                            <label class="required">{{
                                $t("requestDelivery.deliveryFee")
                            }}</label>
                            <input
                                v-model="model.delivery_fee"
                                type="number"
                                :placeholder="$t('requestDelivery.deliveryFee')"
                                class="form-control"
                            />
                            <div
                                class="tw-text-red-500"
                                v-if="errors.has('delivery_fee')"
                            >
                                {{ errors.first("delivery_fee") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <div class="row">
                        <div class="col-md-6 tw-space-y-1">
                            <label>{{ $t("requestDelivery.extraFee") }}</label>
                            <input
                                v-model="model.extra_fee"
                                type="number"
                                :placeholder="$t('requestDelivery.extraFee')"
                                class="form-control"
                                style="width: 98%"
                            />
                            <div
                                class="tw-text-red-500"
                                v-if="errors.has('extra_fee')"
                            >
                                {{ errors.first("extra_fee") }}
                            </div>
                        </div>
                        <div class="col-md-6 tw-space-y-1">
                            <label>{{ $t("requestDelivery.taxiFee") }}</label>
                            <div class="tw-flex">
                                <input
                                    v-model="model.taxi_fee"
                                    type="number"
                                    :placeholder="$t('requestDelivery.taxiFee')"
                                    class="tw-border-b tw-border-t tw-border-l tw-px-3 tw-rounded-tl tw-rounded-bl tw-w-full tw-outline-none"
                                    style="padding: 5px; 0"
                                />
                                <span
                                    class="tw-px-3 tw-bg-blue-500 tw-text-white tw-flex tw-items-center tw-rounded-tr-md tw-rounded-br-md"
                                    >៛</span
                                >
                            </div>
                            <div
                                class="tw-text-red-500"
                                v-if="errors.has('taxi_fee')"
                            >
                                {{ errors.first("taxi_fee") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.feePaidBy")
                    }}</label>
                    <a-select
                        v-model="model.fee_paid_by"
                        class="tw-w-full"
                        :options="[
                            { value: 2, label: 'Shop' },
                            { value: 1, label: 'Buyer' }
                        ]"
                    ></a-select>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label
                        :class="{ required: model.delivery_status_id != 8 }"
                        >{{ $t("requestDelivery.paymentMethod") }}</label
                    >
                    <a-select
                        v-model="model.payment_method_id"
                        :options="paymentMethod"
                        class="tw-w-full"
                    ></a-select>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('payment_method_id')"
                    >
                        {{ errors.first("payment_method_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label
                        :class="{ required: model.delivery_status_id != 8 }"
                        >{{ $t("requestDelivery.amountCollectUsd") }}</label
                    >
                    <input
                        v-model.number="model.amount_collected_usd"
                        type="number"
                        :placeholder="$t('requestDelivery.amountCollectUsd')"
                        class="form-control"
                        @input="onDollarInput"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('amount_collected_usd')"
                    >
                        {{ errors.first("amount_collected_usd") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label
                        :class="{ required: model.delivery_status_id != 8 }"
                        >{{ $t("requestDelivery.amountCollectKhr") }}</label
                    >
                    <input
                        v-model.number="model.amount_collected_khr"
                        type="number"
                        :placeholder="$t('requestDelivery.amountCollectKhr')"
                        class="form-control"
                        @input="onRielInput"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('amount_collected_khr')"
                    >
                        {{ errors.first("amount_collected_khr") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{ $t("requestDelivery.remark") }}</label>
                    <textarea
                        v-model="model.delivery_status_remark"
                        rows="2"
                        class="form-control"
                        :placeholder="$t('requestDelivery.remark')"
                    ></textarea>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('delivery_status_remark')"
                    >
                        {{ errors.first("delivery_status_remark") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button @click.prevent="$emit('cancel')">{{
                        $t("cancel")
                    }}</ts-button>
                    <ts-button
                        color="primary"
                        :disabled="loading"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { ceil, debounce } from "lodash";

export default {
    name: "form-edit",
    props: ["editData"],
    data() {
        return {
            loading: false,
            errors: new Errors(),
            delivery_id: null,
            model: {
                delivery_id: null,
                driver_id: undefined,
                service_type_id: "",
                vehicle_type_id: "",
                delivery_status_id: "",
                delivery_status_remark: "",
                receiver_name: "",
                receiver_phone: "",
                receiver_location: "",
                fx_rate: 0,
                packaging_fee: 0,
                pickup_fee: 0,
                agency_fee: 0,
                cod_amount: 0,
                delivery_fee: 0,
                extra_fee: 0,
                taxi_fee: 0,
                fee_paid_by: "",
                payment_method_id: 1,
                amount_collected_usd: 0,
                amount_collected_khr: 0,
                distance_km: null
            }
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        ...mapState("agency/requestDelivery", ["formModels"]),
        driverList() {
            if (!this.formModels.driver) return [];
            return this.formModels.driver;
        },
        vehicleType() {
            if (!this.formModels.vehicleType) return [];
            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }));
        },
        serviceType() {
            if (!this.formModels.serviceType) return [];
            return this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_kh
            }));
        },
        paymentMethod() {
            if (!this.formModels.paymentMethod) return [];
            return this.formModels.paymentMethod.map(el => ({
                value: el.payment_method_id,
                label: el.payment_method
            }));
        },
        deliveryStatus() {
            if (!this.formModels.deliveryStatus) return [];
            return this.formModels.deliveryStatus.map(el => ({
                value: el.delivery_status_id,
                label: el.delivery_status
            }));
        }
    },
    methods: {
        ...mapActions("agency/requestDelivery", [
            "update",
            "getFormViewData",
            "getDistancePrice",
            "calTransportationFee"
        ]),
        handleDriverSearch: debounce(async function(value) {
            this.loading = true;
            await this.getFormViewData({
                index: "driver",
                params: {
                    fnName: "driver",
                    search_driver: value
                }
            });
            this.loading = false;
        }, 500),
        onUpdate() {
            this.loading = true;
            this.update({
                id: this.model.delivery_id,
                data: this.model
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async fetchData() {
            this.loading = true;
            await this.getFormViewData({ params: undefined, index: undefined });
            this.setEdit();
            this.loading = false;
        },
        async setEdit() {
            this.model.delivery_id = this.editData.delivery_id;
            this.model.driver_id = this.editData.driver_id;
            this.model.service_type_id = this.editData.service_type_id;
            this.model.vehicle_type_id = this.editData.vehicle_type_id;
            this.model.delivery_status_id = this.editData.delivery_status_id;
            // this.model.delivery_status_remark = ''
            this.model.delivery_status_remark = this.editData.delivery_status_remark;
            this.model.receiver_name = this.editData.receiver_name;
            this.model.receiver_phone = this.editData.receiver_phone;
            this.model.receiver_location = this.editData.receiver_location;
            this.model.fx_rate = this.editData.fx_rate;
            this.model.packaging_fee = this.editData.packaging_fee;
            this.model.pickup_fee = this.editData.pickup_fee;
            this.model.agency_fee = this.editData.agency_fee;
            this.model.cod_amount = this.editData.cod_amount;
            this.model.delivery_fee = this.editData.delivery_fee;
            this.model.extra_fee = this.editData.extra_fee;
            this.model.taxi_fee = this.editData.taxi_fee;
            this.model.fee_paid_by = this.editData.fee_paid_by;
            this.model.payment_method_id = this.editData.payment_method_id
                ? this.editData.payment_method_id
                : 1;
            this.model.amount_collected_usd = this.editData.amount_collected_usd;
            this.model.amount_collected_khr = this.editData.amount_collected_khr;
            await this.getFormViewData({
                index: "driver",
                params: {
                    fnName: "driver",
                    driver_id: this.model.driver_id
                }
            });
            this.model.distance_km = this.editData.distance_km;
        },
        onDollarInput: debounce(function(e) {
            if (
                !this.model.amount_collected_khr &&
                this.model.delivery_status_id == 6
            ) {
                var value =
                    this.model.cod_amount +
                    (this.model.fee_paid_by != 2
                        ? this.model.delivery_fee
                        : 0) -
                    e.target.value;
                var rate = this.model.fx_rate ? this.model.fx_rate : 0;

                this.model.amount_collected_khr = ceil(value * rate, 0);
            }
        }, 800),
        onRielInput: debounce(function(e) {
            if (
                !this.model.amount_collected_usd &&
                this.model.delivery_status_id == 6
            ) {
                var rate = this.model.fx_rate ? this.model.fx_rate : 0;
                var value =
                    this.model.cod_amount +
                    (this.model.fee_paid_by != 2
                        ? this.model.delivery_fee
                        : 0) -
                    e.target.value / rate;
                this.model.amount_collected_usd = ceil(value, 2);
            }
        }, 800),
        onStatusChange: debounce(function(value) {
            if (value == 6 && !this.model.delivery_fee > 0) {
                this.loading = true;
                this.getDistancePrice({
                    shop_id: this.editData.shop_id,
                    service_type_id: this.editData.service_type_id
                })
                    .then(response => {
                        this.model.delivery_fee = response.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }, 500),
        calulate() {
            this.calTransportationFee({
                delivery_id: this.model.delivery_id,
                distance_km: this.model.distance_km
            })
                .then(response => {
                    this.model.delivery_fee = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        "model.distance_km": debounce(function() {
            this.calulate();
        }, 500)
    }
};
</script>
