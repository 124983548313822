<template>
    <div>
        <ts-page-title
            :title="$t('requestDelivery.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('requestDelivery.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div class="tw-flex tw-space-x-2">
                        <a-button type="primary" @click="fetchData">
                            {{ $t('refresh') }}
                        </a-button>
                        <a-range-picker
                            v-model="dateRange"
                            :allowClear="false"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            style="width: 200px"
                            @change="fetchData()"
                        />
                        <a-select
                            v-model="status"
                            style="width: 220px"
                            :options="statusOpt"
                            showSearch
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            @change="fetchData()"
                        >
                        </a-select>
                        <a-select
                            v-model="service_type_id"
                            style="width: 220px"
                            :options="serviceType"
                            showSearch
                            mode="multiple"
                            placeholder="All"
                            :max-tag-count="1"
                            :max-tag-text-length="7"
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            @change="fetchData()"
                        >
                        </a-select>
                    </div>
                    <div class="tw-space-x-3">
                        <a-tooltip :title="$t('requestDelivery.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('requestDelivery.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td class="tw-whitespace-nowrap">
                            {{ record.transaction_date }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.booking_number }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_number }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.shop_code }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.shop_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_start_time }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.driver_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">{{ record.sex }}</td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.t_driver_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">{{ record.t_sex }}</td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.service_type_name_kh }}
                        </td>
                        <td class="tw-text-center">
                            {{ record.number_of_package }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.package_type_kh }}
                        </td>
                        <td class="tw-whitespace-nowrap">
													<ts-accounting-format
														:digit="2" 
														:value="record.amount_collected_usd"></ts-accounting-format>
                        </td>
                        <td class="tw-whitespace-nowrap">
													<ts-accounting-format
														:digit="0"
														currency="KHR" 
														:value="record.amount_collected_khr"></ts-accounting-format>
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.receiver_phone }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.receiver_location }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_status }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_status_remark }}
                        </td>
                        <td class="text-center tw-space-x-3">
                            <a
                                :href="record.bank_transfer_slip"
                                target="_blank"
                                v-if="record.bank_transfer_slip"
                            >
                                <i class="far fa-file-image"></i>
                            </a>
                            <a
                                v-if="$can('update-request-deliveries')"
                                href="#"
                                v-tooltip="$t('edit')"
                                class="text-primary mr-2"
                                @click.prevent="edit(record)"
                            >
                                <i class="fas fa-edit"></i>
                            </a>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
            <a-modal
                v-if="show_form"
                v-model="show_form"
                :title="$t('edit')"
                width="500px"
                :footer="null"
                @cancel="onClose"
            >
                <FormEdit :editData="edit_data" @cancel="onClose" />
            </a-modal>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'
import FormEdit from './components/form-edit.vue'
export default {
    name: 'requestDeliveryIndex',
    components: {
        FormEdit
    },
    data () {
        return {
            loading: false,
            show_form: false,
            edit_data: [],
            serviceType: []
        }
    },
    computed: {
        ...mapState('agency/requestDelivery', [
            'resources',
            'pagination',
            'formModels'
        ]),
        search: {
            get () {
                return this.$store.state.agency.requestDelivery.search
            },
            set (newValue) {
                this.$store.commit(
                    'agency/requestDelivery/SET_SEARCH',
                    newValue
                )
                this.$store.commit('agency/requestDelivery/RESET_CURRENT_PAGE')
            }
        },
        dateRange: {
            get () {
                return this.$store.state.agency.requestDelivery.dateRange
            },
            set (newValue) {
                this.$store.commit(
                    'agency/requestDelivery/SET_DATE_RANGE',
                    newValue
                )
            }
        },
        columns () {
            return [
                { name: this.$t('requestDelivery.transactionDate') },
                { name: this.$t('requestDelivery.bookingNumber') },
                { name: this.$t('requestDelivery.transactionNumber') },
                { name: this.$t('requestDelivery.shopCode') },
                { name: this.$t('requestDelivery.shopName') },
                { name: this.$t('requestDelivery.deliveryStartTime') },
                { name: this.$t('requestDelivery.driverName') },
                { name: this.$t('requestDelivery.sex') },
                { name: this.$t('requestDelivery.transferToDriver') },
                { name: this.$t('requestDelivery.transferToSex') },
                { name: this.$t('requestDelivery.serviceType') },
                { name: this.$t('requestDelivery.numberOfPackage') },
                { name: this.$t('requestDelivery.packageType') },
                { name: this.$t("requestDelivery.collectedUsd") },
                { name: this.$t("requestDelivery.collectedKhr") },
                { name: this.$t('requestDelivery.receiverPhone') },
                { name: this.$t('requestDelivery.receiverLocation') },
                { name: this.$t('requestDelivery.status') },
                { name: this.$t('requestDelivery.remark') },
                { name: this.$t('actions') }
            ]
        },
        statusOpt () {
            return [
                { value: '', label: 'All' },
                { value: 0, label: 'Booking' },
                { value: 1, label: 'Request For Delivery' },
                { value: 2, label: 'On Delivery' },
                { value: 3, label: 'Re-Delivery' },
                { value: 4, label: 'Dropped' },
                { value: 5, label: 'Transferred' },
                { value: 6, label: 'Success' },
                { value: 7, label: 'Cancelled' },
                { value: 8, label: 'Pending' },
                { value: 9, label: 'Return' },
                { value: 10, label: 'Confirm Delivery' },
                { value: 11, label: 'Confirm Booking' }
            ]
        },
        status: {
            get () {
                return this.$store.state.agency.requestDelivery.status
            },
            set (newValue) {
                this.$store.commit(
                    'agency/requestDelivery/SET_STATUS',
                    newValue
                )
            }
        },
        service_type_id: {
            get () {
                return this.$store.state.agency.requestDelivery.service_type_id
            },
            set (newValue) {
                this.$store.commit(
                    'agency/requestDelivery/SET_SERVICE_TYPE',
                    newValue
                )
            }
        }
    },
    methods: {
        ...mapActions('agency/requestDelivery', ['getFormViewData']),
        async getFilterData () {
            await this.getFormViewData({
                index: 'serviceType',
                params: {
                    fnName: 'serviceType'
                }
            })
            this.serviceType = this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en + '|' + el.service_type_name_kh
            }))
        },
        async fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('agency/requestDelivery/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        edit (record) {
            this.show_form = true
            this.edit_data = record
        },
        onClose () {
            this.show_form = false
            this.fetchData()
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getFilterData()
            vm.fetchData()
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('agency/requestDelivery/RESET_STATE')
        next()
    }
}
</script>
